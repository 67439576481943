import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';
import { getUrl, uploadMedia } from 'api/uploadMedia';
import JoditEditor, { Jodit } from 'jodit-react';
import { values } from 'lodash';
import { getImageUrl } from 'utils/tools';


export const MEDIA_TYPE = {
  IMAGE: 'image',
  VIDEO: 'video',
};
const uploadFn = async (param) => {
  const responseS3 = await getUrl(param.file.name, param.file.type);

  const xhr = new XMLHttpRequest();

  const successFn = () => {
    param.success({
      url: responseS3.url,
      meta: {
        id: responseS3.name,
        title: responseS3.name,
        alt: responseS3.name,
        loop: true,
        autoPlay: true,
        controls: true,
        poster: responseS3.url,
      },
    });
  };

  const progressFn = (event) => {
    param.progress((event.loaded / event.total) * 100);
  };

  const errorFn = () => {
    param.error({
      msg: 'unable to upload.',
    });
  };

  xhr.upload.addEventListener('progress', progressFn, false);
  xhr.addEventListener('load', successFn, false);
  xhr.addEventListener('error', errorFn, false);
  xhr.addEventListener('abort', errorFn, false);

  xhr.open('PUT', responseS3.uploadUrl, true);
  xhr.send(param.file);
};


function refactorPasteHtmlNestedSpan(nestedSpans) {
  const regexPattern = /(<span>)+|(<\/span>)+/g;

  // Step 2: Use the regex to replace the nested tags with a single <span> tag
  const refactoredSpans = nestedSpans.replace(regexPattern, (match, p1, p2) => {
    if (p1) return '<span>';
    if (p2) return '</span>';
  });
  return refactoredSpans;
}

function remove_style(all) {
  let i = all.length;
  let j, is_hidden;

  // Presentational attributes.
  const attr = [
    'align',
    'background',
    'bgcolor',
    'border',
    'cellpadding',
    'cellspacing',
    'color',
    'face',
    'height',
    'hspace',
    'marginheight',
    'marginwidth',
    'noshade',
    'nowrap',
    'valign',
    'vspace',
    'width',
    'vlink',
    'alink',
    'text',
    'link',
    'frame',
    'frameborder',
    'clear',
    'scrolling',
    // 'style',
  ];

  const REMOVE_STYLES_KEY = [
    'font',
    'font-family',
    'font-size',
    'font-style',
    'font-variant',
    'font-size-adjust',
    'line-height',
    'margin',
    'margin-top',
    'margin-right',
    'margin-bottom',
    'margin-left',
    'padding',
    'padding-top',
    'padding-right',
    'padding-bottom',
    'padding-left',
  ];

  const attr_len = attr.length;

  while (i--) {
    is_hidden = all[i].style.display === 'none';

    j = attr_len;

    while (j--) {
      all[i].removeAttribute(attr[j]);
      REMOVE_STYLES_KEY.forEach((key) => {
        all[i].style.removeProperty(key);
      });
      if(all[i].tagName === 'p'||all[i].tagName === 'P') {
        all[i].style.setProperty('font-size', '16px');
      }
      // all[i].style.setProperty('line-height', '1.5');
    }

    // Re-hide display:none elements,
    // so they can be toggled via JS.
    if (is_hidden) {
      // eslint-disable-next-line
      all[i].style.display = 'none';
      is_hidden = false;
    }
  }
}

export const TextEditor = ({
  value,
  onChange,
  placeholder,
  resource,
  isKeepOriginalName,
}) => {
  const reactQuillRef = useRef(null);
  const staticParams = useMemo(()=>({bulk:[]}),[]);
  // useEffect(() => {
  //   const onLoad = () => {
  //     if (document.querySelector(`#${quillId}`)) {
  //       reactQuillRef.current = new Quill(`#${quillId}`, {
  //         theme: 'snow',
  //         modules: modules,
  //         formats: formats,
  //         placeholder: placeholder || 'Write something awesome...',
  //       });
  //       reactQuillRef.current.on(
  //         'text-change',
  //         function (delta, oldDelta, source) {
  //           console.log('A change was made', delta, oldDelta, source);
  //           if (onChange) {
  //             onChange(reactQuillRef.current.root.innerHTML);
  //           }
  //         },
  //       );
  //     }
  //   };
  //   onLoad();
  // }, [reactQuillRef]);

  // useEffect(() => {
  //   if (
  //     reactQuillRef.current &&
  //     value &&
  //     reactQuillRef.current?.toText?.() !== value
  //   ) {
  //     const _div = document.createElement('div');
  //     _div.innerHTML = value || '';
  //     _div.textContent &&
  //       reactQuillRef.current?.setValue(
  //         _div.textContent && BraftEditor.createEditorState(value),
  //       );
  //   }
  // }, [reactQuillRef, value]);

  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/docs/,
      placeholder: placeholder || 'Start typings...',
      removeButtons: ['print', 'selectall', 'speechRecognize'],
      toolbarAdaptive: false,
      // enter: 'div' as 'div' | 'br' | 'p',
      controls: {
        lineHeight: {
          list: Jodit.atom([1, 1.5, 1.6, 1.7, 1.8, 1.9, 2]),
        },
        fontsize: {
          list: Jodit.atom([8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 48, 60, 72, 96])
        }
      },
      // image: {
      //   allowResize: true,   // Allow image resizing
      //   editSrc: false,       // Allow editing the source URL
      //   editAlt: true,       // Allow editing the alt attribute
      //   editTitle: true,     // Allow editing the image title
      //   editSize: true       // Allow changing the size
      // },
      uploader: {
        url: `https://api.restful-api.dev/objects`,
        format: 'json',
        
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
        },
        contentType: function () {
          return 'application/json';
        },
        buildData: async (data) => {
          return new Promise((resolve) => {
            // const files = data.getAll('files[0]');
            const files = values(
              Object.fromEntries(
                Array.from(
                  data.keys().filter((key) => key.includes('files')),
                ).map((key) => [
                  key,
                  data.getAll(key).length > 1
                    ? data.getAll(key)
                    : data.get(key),
                ]),
              ),
            );

            Promise.all(
              files.map((file) =>
                getUrl(
                  file?.name || '',
                  file.type,
                  resource,
                  isKeepOriginalName,
                ),
              ),
            ).then(async (responseS3s) => {
              await Promise.all(
                responseS3s.map((responseS3, index) =>
                  uploadMedia(responseS3.uploadUrl, files[index]),
                ),
              );
              const bulk = await Promise.all(
                responseS3s.map((responseS3, index) => {
                  const file = files[index]
                  const img = new Image();
                  img.src = responseS3.url;
                  return new Promise((_resolve) => {
                    img.onload = function () {
                      _resolve({
                        width: img.naturalWidth,
                        height: img.naturalHeight,
                        ...file,
                        alternativeText: file.name,
                        caption: file.name,
                        dimension: `${img.naturalWidth}x${img.naturalHeight}`,
                        extension: file.type,
                        name: file.name,
                        size: file.size,
                        status: 'done',
                        type: file.type,
                        uid: `${new Date().getTime()}`,
                        xhr: responseS3?.url,
                        url: responseS3?.name,
                        mediaType: file?.type?.includes('image')
                          ? MEDIA_TYPE['IMAGE']
                          : MEDIA_TYPE['VIDEO'],
                        previewUrl: responseS3?.url,

                        lastModified: 1722743721437,
                        lastModifiedDate: '2024-08-04T03:55:21.437Z',
                        response: responseS3?.name,
                        originFileObj: { uid: `${new Date().getTime()}` },
                        percent: 100,
                      });
                    };
                  });
                }),
              );
              staticParams.bulk = bulk;
              resolve({
                bulk,
              });
            });
          });
        },
        isSuccess: function (resp) {
          console.log(resp);
          return true;
        },
        getMessage: function (resp) {
          return resp?.msg;
        },
        process: function (resp) {
          return {
            files: resp,
            path: resp?.[0]?.url,
            baseurl: process.env.REACT_APP_PHOTO_HOST,
            error: resp?.error,
            msg: resp?.msg,
          };
        },
        defaultHandlerSuccess: function (data, resp) {
          const field = 'files';
          if (staticParams.bulk.length) {
            const jodit = Jodit.make(reactQuillRef.current);
            for (let i = 0; i < staticParams.bulk.length; i += 1) {
              jodit.selection?.insertImage?.(staticParams.bulk[i]?.previewUrl
                // data.baseurl + `/${staticParams.bulk[i]?.url}`,
              );
            }
          }
        },
      },
      events: {
        paste: function (e) {
          const target =
            e.clipboardData ||
            window.Clipboard.toString() ||
            e.originalEvent.clipboardData;
          const _div = document.createElement('div');
          _div.innerHTML = refactorPasteHtmlNestedSpan(
            target.getData('text/html')?.replace(/(^|;)\s*font-[^;]+/g, ''),
          );
          remove_style(_div.querySelectorAll('*'));
          const newContent = _div.innerHTML;
          navigator.clipboard.writeText(newContent);
          // eslint-disable-next-line
          e.value = newContent;
          // eslint-disable-next-line
          e.target.value = newContent;
          e?.selection?.insertHTML(newContent);  
          e.clipboardData.setData('text/html', '');
          target.setData('text/html', '');
          return e;
        },
        beforePasteInsert: function (e) {
          const _div = document.createElement('div');
          _div.innerHTML = refactorPasteHtmlNestedSpan(
            e?.replace(/(^|;)\s*font-[^;]+/g, ''),
          );
          remove_style(_div.querySelectorAll('*'));
          const newContent = _div.innerHTML;
          return newContent;
        },
        // paste: function (e) {
        //   const target =
        //     e.clipboardData ||
        //     window.Clipboard.toString() ||
        //     e.originalEvent.clipboardData;
        //   // const jodit = Jodit.make(reactQuillRef.current);
        //   // await jodit.waitForReady();
        //   // const _div = document.createElement('div');
        //   // _div.innerHTML = refactorPasteHtmlNestedSpan(html);
        //   // console.log('target', target, html);
        //   // // remove_style(_div.querySelectorAll('*'));
        //   // // console.log('123123sdfds', event.data);
        //   // jodit.selection.insertHTML(_div.innerHTML);
        //   const newContent = e.target.innerHTML.replace(
        //     /(^|;)\s*font-[^;]+/g,
        //     '',
        //   ); // remove font-family...
        //   e.value = refactorPasteHtmlNestedSpan(newContent);
        //   e.target.value = refactorPasteHtmlNestedSpan(newContent);
        //   e.target.innerHTML = refactorPasteHtmlNestedSpan(newContent);
        //   return e;
        // },
        // afterPaste: async function (e) {
        //   const newContent = e.target.innerHTML.replace(
        //     /(^|;)\s*font-[^;]+/g,
        //     '',
        //   ); // remove font-family...
        //   e.value = refactorPasteHtmlNestedSpan(newContent);
        //   e.target.value = refactorPasteHtmlNestedSpan(newContent);
        //   e.target.innerHTML = refactorPasteHtmlNestedSpan(newContent);
        //   const jodit = Jodit.make(reactQuillRef.current);
        //   await jodit.waitForReady();
        //   const _div = document.createElement('div');
        //   _div.innerHTML = e.target.innerHTML;
        //   // remove_style(_div.querySelectorAll('*'));
        //   // console.log('123123sdfds', event.data);
        //   jodit.selection.insertHTML(_div.innerHTML);
        // },
      },
      // askBeforePasteFromWord: false,
      // askBeforePasteHTML: false,
      // filebrowser: {
      //   create: {
      //     url: 'connector/create.php',
      //     process: (resp) => {
      //       return {
      //         success: true,
      //         time: '2021-12-23 16:30:01',
      //         data: {
      //           sources: [
      //             {
      //               baseurl: 'https://xdsoft.net/jodit/finder/files/',
      //               path: '/folder/somepath/',
      //               files: resp,
      //               name: 'default',
      //             },
      //           ],
      //           code: 220,
      //         },
      //       };
      //     },
      //   },
      //   items: {
      //     url: 'https://lighto-api-dev.devtify.com/admin/medias',
      //     process: (resp) => {
      //       console.log('resp', resp);
      //       return {
      //         success: true,
      //         time: '2021-12-23 16:30:01',
      //         data: {
      //           sources: [
      //             {
      //               baseurl: 'https://xdsoft.net/jodit/finder/files/',
      //               path: '/folder/somepath/',
      //               files: resp,
      //               name: 'default',
      //             },
      //           ],
      //           code: 220,
      //         },
      //       };
      //     },
      //   },
      // },
    }),
    [placeholder,staticParams, reactQuillRef],
  );

  return (
    <>
      {/* <TextEditorStyles className="rich-text"> */}
        {/* <BraftEditor
          ref={reactQuillRef}
          onChange={onChange}
          // excludeControls={['']}
          media={{ uploadFn }}
          language="en"
          contentStyle={{ height: 300 }}
        /> */}
        <JoditEditor
          ref={reactQuillRef}
          value={value || '<p></p>'}
          config={config}
          // tabIndex={1} // tabIndex of textarea
          onBlur={(newContent) => onChange(newContent)} // preferred to use only this option to update the content for performance reasons
          // onChange={(newContent) => {}}
        />
      {/* </TextEditorStyles> */}
    </>
  );
};

export default React.memo(TextEditor);

const TextEditorStyles = styled.div`
  // .jodit-workplace {
  //   max-height: 50vh !important;
  //   overflow: auto;
  // }
  // .jodit_fullsize-box_true .jodit-workplace {
  //   max-height: 100vh !important;
  //   overflow: auto;
  // }
  // .ql-lheight {
  //   width: 74px;
  //   .ql-picker-item:before,
  //   .ql-picker-label:before {
  //     content: attr(data-label);
  //   }
  // }

  // .lheight-normal {
  //   line-height: normal;
  // }
  // .lheight-tight {
  //   line-height: 1;
  // }
  // .lheight-comfortable {
  //   line-height: 2;
  // }
  // .lheight-spacious {
  //   line-height: 2.5;
  // }

  // /* align left */
  // div.ql-editor .ql-image-align-left,
  // div.ql-editor .ql-iframe-align-left {
  //   float: left;
  //   margin: 0.5rem 1rem 0.5rem 0;
  // }
  // /* align center */
  // div.ql-editor .ql-image-align-center,
  // div.ql-editor .ql-iframe-align-center {
  //   margin: 0.5rem auto;
  //   display: block;
  // }
  // div.ql-editor .ql-image-align-center {
  //   width: -moz-fit-content;
  //   width: fit-content;
  // }
  // /* align right */
  // div.ql-editor .ql-image-align-right,
  // div.ql-editor .ql-iframe-align-right {
  //   float: right;
  //   margin: 0.5rem 0 0.5rem 1rem;
  // }

  // /* image caption */
  // div.ql-editor [class^='ql-image-align-'][data-title]:not([data-title='']) {
  //   position: relative;
  //   margin-bottom: 2rem;
  // }
  // div.ql-editor
  //   [class^='ql-image-align-'][data-title]:not([data-title=''])::after {
  //   content: attr(data-title);
  //   display: block;
  //   position: absolute;
  //   bottom: -2rem;
  //   padding-top: 2px;
  //   margin-bottom: 5px;
  //   width: -webkit-fill-available;
  //   font-size: 0.9rem;
  //   background-color: white;
  // }
  // div.ql-editor .ql-image-align-left[data-title]:not([data-title=''])::after {
  //   text-align: left;
  // }
  // div.ql-editor .ql-image-align-center[data-title]:not([data-title=''])::after {
  //   text-align: center;
  // }
  // div.ql-editor .ql-image-align-right[data-title]:not([data-title=''])::after {
  //   text-align: right;
  // }
`;
